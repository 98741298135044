div.header {
  white-space: nowrap;
  overflow: auto;
  background-color: #57d5f2;
  display: flex;
  justify-content: space-between;
  box-shadow: 5px 5px 10px #888888;
}

div.header p {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  font-family: Arial;
}

span {
  font-family: Arial;
}
